import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiUrl } from '../Settings/Config';
import axios from 'axios';
import { setSearch } from '../../store/reducers/search';

const useSearch = () => {
	const dispatch = useDispatch();
	const popular = useSelector((state) => state.search?.popular);
	const recommendation = useSelector((state) => state.search?.recommendation);
	const customerID = useSelector((state) => state.user?.customerID);
	const latitude = useSelector((state) => state.user?.latitude);
	const longitude = useSelector((state) => state.user?.longitude);

	const [query, setQuery] = useState('');
	const [timeoutSearch, setTimeoutSearch] = useState(null);
	const [outlets, setOutlets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


	const handleGetProduct = async (value = '') => {
		if (!value) return;
		console.log('handleGetProduct: ', value);
		try {
      setIsLoading(true);
			const url = `${apiUrl}product/search`;
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};

			const data = new FormData();
			data.append('userID', customerID);
			data.append('latitude', latitude);
			data.append('longitude', longitude);
			data.append('keyword', value);
			console.log('data: ', data);

			const res = await axios.post(url, data, config);
			if (res?.data?.status === 'ok') {
				setOutlets(res?.data?.data || []);
			}
      setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
      setIsLoading(false);
		}
	};

	const handleGetRecomendation = async () => {
		console.log('handleGetRecomendation');
		try {
      setIsLoading(true);
			const url = `${apiUrl}outlets/list-recommend?latitude=${latitude}&longitude=${longitude}`;
			const res = await axios.get(url);
			if (res?.data?.status === 'ok') {
				dispatch(
					setSearch({
						recommendation: res?.data?.data || [],
					}),
				);
			}
      setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
      setIsLoading(false);
		}
	};

	const handleGetPopularSearch = async () => {
		console.log('handleGetPopularSearch');
		try {
      setIsLoading(true);
			const url = `${apiUrl}product/suggest-keyword`;
			const res = await axios.get(url);
			if (res?.data?.status === 'ok') {
				dispatch(
					setSearch({
						popular: res?.data?.data || [],
					}),
				);
			}
      setIsLoading(false);
		} catch (error) {
			console.log('error: ', error);
      setIsLoading(false);
		}
	};

	useEffect(() => {
		handleGetPopularSearch();
		handleGetRecomendation();
	}, []);

	const handleSearch = (value) => {
		console.log('handleSearch: ', value);
		setQuery(value);
		clearTimeout(timeoutSearch);
		setTimeoutSearch(
			setTimeout(() => {
        setIsLoading(true);
				handleGetProduct(value);
			}, 500),
		);
	};

	return {
		outlets,
		popular,
		recommendation,
		query,
		handleSearch,
    isLoading,
	};
};

export default useSearch;
