import React from 'react';
import { showPrice } from '../../Settings/SettingHelper';

function PaymentSummary({ orderDetails }) {
  return (
    <div
      className="px-4 mt-4"
      style={{
        paddingBottom: '25px',
        marginBottom: orderDetails?.orderStatus !== '6' ? '77px' : '103px',
      }}
    >
      <h3 className="text-base font-semibold">Payment Summary</h3>
      <div
        className="mt-4"
        style={{
          border: '1px solid #D3D3D3',
          padding: '16px 12px',
          borderRadius: '6px',
        }}
      >
        <ul>
          <li className="flex justify-between">
            <span>Subtotal ({orderDetails.orderTotalItems} Menu)</span>
            <p>Rp{showPrice(orderDetails.subTotal)}</p>
          </li>
          {parseInt(orderDetails.deliveryCharge) > 0 && (
            <li className="flex justify-between">
              <span>Delivery Fee</span>
              <p>
                Rp
                {showPrice(
                  parseFloat(orderDetails.deliveryCharge) +
                    parseFloat(orderDetails?.deliveryFee)
                )}
              </p>
            </li>
          )}
          {parseInt(orderDetails?.packagingFee) > 0 && (
            <li className="flex justify-between">
              <span>Other Fee(s)</span>
              <p>Rp{showPrice(orderDetails?.packagingFee)}</p>
            </li>
          )}
          {parseInt(orderDetails?.platformFee) > 0 && (
            <li className="flex justify-between">
              <span>Processing Fee</span>
              <p>Rp{showPrice(orderDetails?.platformFee)}</p>
            </li>
          )}

          {orderDetails.discount.length > 0 &&
            orderDetails.discount.map((item, index) => {
              return (
                <li
                  className="ts-discount flex justify-between"
                  key={index}
                >
                  <span>Discount ({item.promoCode})</span>
                  <strong>-Rp{showPrice(item.promoAmount)}</strong>
                </li>
              );
            })}
          {orderDetails.voucher.length > 0 &&
            orderDetails.voucher.map((item, index) => {
              return (
                <li
                  className="ts-discount flex justify-between"
                  key={index}
                >
                  <span>Voucher {item.name}</span>
                  <strong>-Rp{showPrice(item.amount)}</strong>
                </li>
              );
            })}
          <li
            className="flex justify-between"
            style={{
              borderTop: '1px solid #D3D3D3',
              marginTop: '12px',
              paddingTop: '12px',
            }}
          >
            <span
              className="text-sm text-gray-800"
              style={{ fontWeight: '700' }}
            >
              Total Payment
            </span>
            <strong
              className="text-sm text-gray-800"
              style={{ fontWeight: '700' }}
            >
              Rp{showPrice(orderDetails.totalAmount)}
            </strong>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PaymentSummary;
