import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	outlet_id: null,
	product: [],
	outlet: null,
	cart: null,
	cartItem: null,
	item: null,
  notes: null,
	loading: false,
	simple: null,
	combo: null,
  delivery: null,
	pathnersList: [],
	paymentMethodList: [
		{
			id: 2,
			category: 'Payment Method',
			image_url: '',
			payment_method: [
				{
					code: 'ULTRA-VOUCHER',
					name: 'Ultra Value',
					minimum_amount: 1,
					maximum_amount: 10000000,
					status: 'ACTIVE',
					fee: 0,
					image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-12-23T03%3A16%3A35.175Z1577070994-group.jpeg',
				},
			],
		},
		{
			id: 4,
			category: 'Other Payment Method',
			image_url: '',
			payment_method: [
				{
					code: 'VABNI',
					name: 'Virtual Account BNI',
					minimum_amount: 10000,
					maximum_amount: 100000000,
					status: 'ACTIVE',
					fee: 0,
					image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A40%3A55.520Z1662086455-BNI.png',
				},
				{
					code: 'VADIRECTBCA',
					name: 'Virtual Account Direct BCA',
					minimum_amount: 15000,
					maximum_amount: 9000000,
					status: 'ACTIVE',
					fee: 0,
					image_url: 'https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-09-30T03%3A39%3A05.606Z1569814745-group.jpeg',
				},
				{
					code: 'VAMANDIRI',
					name: 'Virtual Account Mandiri',
					minimum_amount: 10000,
					maximum_amount: 100000000,
					status: 'ACTIVE',
					fee: 0,
					image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2023-07-11T11%3A21%3A41.987Z1689074501-group.png',
				},
			],
		},
	],
};

const cart = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		setCart: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearCart: () => {
			return initialState;
		},
	},
});

// Export actions and reducer
export const { setCart, clearCart } = cart.actions;
export default cart.reducer;
