import React from 'react';
import shop from '../../../common/images/shop-ilustration.svg';
import locationPin from '../../../common/images/location-pin.svg';

function DeliveryLocation({ orderDetails }) {
  return (
    <div className="px-4">
      <div className="flex items-center">
        <img src={shop} alt="Shop" />
        <div className="ml-3">
          <p className="text-xs text-gray-500 font-normal">
            Restaurant Location
          </p>
          <p className="text-base text-gray-500 font-semibold">
            {orderDetails.orderItems[0].storeName}
          </p>
        </div>
      </div>
      <div
        className="flex items-center mt-5"
        style={{ marginBottom: '15px' }}
      >
        <img src={locationPin} alt="Location" />
        <div className="ml-3" style={{ maxWidth: 'calc(100% - 36px)' }}>
          <p className="text-xs text-gray-500 font-normal">
            Delivery Location
          </p>
          <p className="text-base text-gray-500 font-semibold truncate">
            {orderDetails.address}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DeliveryLocation;
