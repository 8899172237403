import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { ReactComponent as StarFull } from '../../../common/images/star.svg';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'react-cookies';
import base64 from 'base-64';
import qs from 'qs';
import { apiUrl, unquieID } from '../../Settings/Config';
import { GET_ORDER_LIST } from '../../../actions';

function RatingComponent({ orderID, orderType, orderRating }) {
	const dispatch = useDispatch();

  const customerID = useSelector((store) => store?.user?.customerID)
  console.log('customerID: ', customerID);
	const [ratingValue, setRatingValue] = useState(0);

	const handleRating = (rate) => {
		setRatingValue(rate);
	};

	const ratingChanged = (orderID, newRating) => {
		console.log('ratingChanged');
		const postObject = {
			unquieid: unquieID,
			customerID: base64.encode(customerID),
			orderID: base64.encode(orderID),
			rating: newRating,
		};
    console.log('postObject: ', postObject);
		axios
			.post(apiUrl + 'orders/createRating', qs.stringify(postObject))
			.then((res) => {
				if (res.data.status === 'ok') {
					console.log('res: ', res);
					// dispatch({ type: GET_ORDER_LIST, orderType });
				}
			})
			.catch((error) => {
				console.log(error);
			});
		console.log('orderID: ', orderID);
		console.log('newRating: ', newRating);
	};

	if (ratingValue > 0) {
		ratingChanged(orderID, ratingValue);
	}

	return (
		<div
			className='w-full flex flex-col items-center justify-center py-3'
			style={{
				border: '1px solid #D3D3D3',
				borderRadius: '6px',
				gap: '12px',
				marginBottom: '20px',
			}}>
			<h3 className='text-sm font-semibold'>Let's give a rating!</h3>
			<Rating
				initialValue={orderRating ? parseFloat(orderRating) : 0}
				readonly={orderRating ? true : false}
				onClick={handleRating}
				transition
				allowFraction
				emptyIcon={<StarFull width={36} height={36} style={{ display: 'inline' }} />}
				fillIcon={<StarFull width={36} height={36} style={{ display: 'inline' }} />}
			/>
		</div>
	);
}

export default RatingComponent;
