import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiUrl, deliveryId, unquieID } from '../../components/Settings/Config';
import { decodeValue, encodeValue, formatRupiah, isEmptyData } from '../../components/Helpers/SettingHelper';
import '../../tailwind.css';
import { useHistory } from 'react-router';
import { ArrowLeftIcon, ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { HeartIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { AutoVoucherIcon, DiscountIcon, DoubleNoteIcon, LoaderIcon, MotorbikeIcon, TicketDiscountIcon, UltraVoucherIcon } from '../../utils/svg';
import Footer from '../../components/Footer';
import qs from 'qs';
import CartDetail from '../../components/Cart';
import Voucher from '../../components/Voucher';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useToast from '../../components/Toast';
import TooltipInfo from './TooltipInfo';
import VoucherDetail from '../../components/Voucher/VoucherDetail';
import { BottomSheet } from 'react-spring-bottom-sheet';
import ProductSimple from '../../components/Product/simple';
import { ComboDetail } from '../../components/Product/combo';
import { useDispatch, useSelector } from 'react-redux';
import { setCart } from '../../store/reducers/cart';
import { setVoucher } from '../../store/reducers/voucher';
import { setUser } from '../../store/reducers/user';
import { clearPayment, setPayment } from '../../store/reducers/payment';
import DeliveryLocationDetail from '../../components/Home/DeliveryLocation/LocationDetail';
import { TooFar } from '../../components/Errors';
import Loader from '../../components/Loader';

const Summary = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { slug } = useParams();

	const { showToast } = useToast();

	const customerID = useSelector((store) => store?.user?.customerID);
	const address = useSelector((store) => store?.user?.address);
	const firstName = useSelector((store) => store?.user?.firstName);
	const lastName = useSelector((store) => store?.user?.lastName);
	const email = useSelector((store) => store?.user?.email);
	const phone = useSelector((store) => store?.user?.primary_phone);
	const latitude = useSelector((store) => store?.user?.latitude);
	const longitude = useSelector((store) => store?.user?.longitude);
	const village = useSelector((store) => store?.user?.village);
	const district = useSelector((store) => store?.user?.district);
	const city = useSelector((store) => store?.user?.city);
	const state = useSelector((store) => store?.user?.state);
	const country = useSelector((store) => store?.user?.country);
	const postalCode = useSelector((store) => store?.user?.postalCode);
	const notes = useSelector((store) => store?.cart?.notes);

	const token = useSelector((store) => store?.user?.token);
	const refresh_token = useSelector((store) => store?.user?.refresh_token);

	const store = useSelector((store) => store?.cart?.outlet);
	const cart = useSelector((store) => store?.cart?.cart);

	const pathnersList = useSelector((store) => store?.cart?.pathnersList);
	const delivery = useSelector((store) => store?.cart?.delivery);

	const paymentMethodList = useSelector((store) => store?.cart?.paymentMethodList);

	const myVoucherList = useSelector((store) => store?.voucher?.myVoucherList);

	const myVoucherNominal = useSelector((store) => store?.voucher?.myVoucherNominal);

	const b2bVoucherList = useSelector((store) => store?.voucher?.b2bVoucherList);

	const b2bVoucherNominal = useSelector((store) => store?.voucher?.b2bVoucherNominal);
	const b2BVoucherPrice = useSelector((store) => store?.voucher?.b2BVoucherPrice);
	const b2BVoucherSaved = useSelector((store) => store?.voucher?.b2BVoucherSaved);

	const applyB2BVoucher = useSelector((store) => store?.voucher?.applyB2BVoucher);

	const customApplyVoucher = useSelector((store) => store?.voucher?.customApplyVoucher);

	const [isLoadingCart, setisLoadingCart] = useState(false);

	const [isShowBottomSheet, setIsShowBottomSheet] = useState(false);

	const [isShowSimpleProduct, setIsShowSimpleProduct] = useState(false);
	const [isShowComboProduct, setIsShowComboProduct] = useState(false);

	const [product, setProduct] = useState(null);
	const [item, setItem] = useState(null);

	const [cartItem, setCartItem] = useState(null);

	const [isLoadingDeliveryList, setisLoadingDeliveryList] = useState(true);
	const [isShowDelivery, setIsShowDelivery] = useState(false);

	const [isLoadingVoucherList, setisLoadingVoucherList] = useState(true);
	const [isShowVoucher, setIsShowVoucher] = useState(false);
	const [isShowMoreVoucher, setIsShowMoreVoucher] = useState(false);
	const [showVoucherDetail, setShowVoucherDetail] = useState({
		isOpen: false,
		data: null,
	});

	const [isShowPaymentMethod, setIsShowPaymentMethod] = useState(false);

	const [isLoadingUvBalance, setIsLoadingUvBalance] = useState(true);
	const [UVBalance, setUVBalance] = useState(null);

	const [isShowDeliveryLocation, setIsShowDeliveryLocation] = useState(false);

	const [isTooFar, setIsTooFar] = useState(false);

	const [orderData, setOrderData] = useState({
		unquieid: unquieID,
		customerID: encodeValue(customerID),
		paymentReference: null,
		sender: {
			firstName: firstName,
			email: email,
			phone: phone,
		},
		deliveryType: null,
		deliveryFee: 0,
		deliverySubsidies: 0,
		notes: notes ?? '',
		paymentType: {
			code: 'VABNI',
			name: 'Virtual Account BNI',
			minimum_amount: 10000,
			maximum_amount: 100000000,
			status: 'ACTIVE',
			fee: 0,
			image_url: 'https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A40%3A55.520Z1662086455-BNI.png',
		},
		origin: null,
		destinations: {
			address: address,
			coordinate: {
				latitude: latitude,
				longitude: longitude,
			},
			village: village,
			district: district,
			city: city,
			province: state,
			postalCode: postalCode,
			state: state,
			country: country,
			recipient: {
				firstName: firstName,
				email: email,
				phone: phone,
				lastName: lastName,
			},
			items: [],
		},
	});

	const orderSchema = Yup.object().shape({
		unquieid: Yup.string().required('Unique ID is required'),
		customerID: Yup.string().required('Customer ID is required'),

		sender: Yup.object().shape({
			firstName: Yup.string().required('First Name is required'),
			email: Yup.string().email('Invalid email format').required('Email is required'),
			phone: Yup.string().required('Phone number is required'),
		}),

		deliveryType: Yup.mixed()
			.test('is-delivery-valid', 'Delivery Type is required', (delivery) => {
				if (typeof delivery === 'object') {
					return true;
				}
				return false;
			})
			.required('Delivery Type is required'),

		notes: Yup.string().optional(),

		paymentType: Yup.mixed()
			.test('is-payment-type-valid', 'Payment Type is required', (payment) => {
				if (typeof payment === 'object' && payment?.code === 'ULTRA-VOUCHER') {
					return Number(UVBalance?.value || 0) > Number(paymentSummary?.totalPayment || 0);
				}
				if (typeof payment === 'object') {
					return true;
				}
				return false;
			})
			.required('Payment Type is required'),

		origin: Yup.object().shape({
			address: Yup.string().required('Origin address is required'),
			keywords: Yup.string().optional(),
			coordinate: Yup.object().shape({
				latitude: Yup.string().required('Origin latitude is required'),
				longitude: Yup.string().required('Origin longitude is required'),
			}),
			village: Yup.string().required('Origin village is required'),
			district: Yup.string().required('Origin district is required'),
			city: Yup.string().required('Origin city is required'),
			province: Yup.string().required('Origin province is required'),
			postalCode: Yup.string().required('Origin postal code is required'),
		}),

		destinations: Yup.object().shape({
			address: Yup.string().required('Destination address is required'),
			coordinate: Yup.object().shape({
				latitude: Yup.string().required('Destination latitude is required'),
				longitude: Yup.string().required('Destination longitude is required'),
			}),
			village: Yup.string().required('Destination village is required'),
			district: Yup.string().required('Destination district is required'),
			city: Yup.string().required('Destination city is required'),
			province: Yup.string().required('Destination province is required'),
			postalCode: Yup.string(),
			recipient: Yup.object().shape({
				firstName: Yup.string().required('Recipient First Name is required'),
				email: Yup.string().email('Invalid email format').required('Recipient email is required'),
				phone: Yup.string().required('Recipient phone number is required'),
			}),
			items: Yup.array().min(1, 'At least one item is required').required('Items are required'),
		}),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: orderData,
		validationSchema: orderSchema,
		onSubmit: async (values, { resetForm }) => {
			await handlePayment(values, resetForm);
		},
	});

	const { errors, touched, handleSubmit, values, setValues, isSubmitting } = formik;

	useEffect(() => {
		setIsShowMoreVoucher(false);
	}, []);

	useEffect(() => {
		if (!isSubmitting) return;
		if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
			for (const key in errors) {
				if (touched[key]) {
					showToast('error', errors[key]);
				}
			}
		}
	}, [isSubmitting]);

	const handleGetStoreDetail = async () => {
		console.log('handleGetStoreDetail');
		try {
			let url = `${apiUrl}store/storeDetails?unquieid=${unquieID}&storeID=${cart?.store?.storeID}&customerID=${customerID}`;

			if (latitude && longitude) {
				url += `&latitude=${latitude}&longitude=${longitude}`;
			}
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const data = res.data?.result;
				dispatch(
					setCart({
						outlet: data,
					}),
				);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const handleCart = async (data) => {
		console.log('handleCart', data);
		try {
			setisLoadingCart(true);
			let postData = {
				unquieid: unquieID,
				availabilityID: deliveryId,
				availabilityName: 'Delivery',
				shopID: encodeValue(store?.storeID),
				customerID: customerID,
			};
			if (data?.productType === 'Simple') {
				postData = {
					...postData,
					...data,
				};
			} else {
				postData = {
					...postData,
					...data,
					productTotalPrice: data?.totalPrice,
					productPrice: data?.price,
					comboset: JSON.stringify(data?.comboset),
				};
			}
			const url = `${apiUrl}cart/${data?.type === 'add' ? 'createCart' : 'updateCartItem'}`;
			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				setCartItem(null);
				setProduct(null);
				setItem(null);
				handleCloseBottomSheet();
				await handleGetCart();
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const handleGetCart = async () => {
		console.log('handleGetCart');
		try {
			setisLoadingCart(true);
			const url = `${apiUrl}cart/loadCartDetails?unquieid=${unquieID}&customerID=${encodeValue(customerID)}&availabilityID=${deliveryId}`;
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res?.data?.status === 'ok') {
				dispatch(
					setCart({
						cart: res?.data?.result || null,
					}),
				);
			}
			if (res?.data?.message === 'There are no items in your cart') {
				showToast('warning', res?.data?.message, 1000);
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const handleGetPathnerList = async () => {
		console.log('handleGetPathnerList', !values?.destinations?.address, values?.destinations?.address);
		try {
			if (!values?.destinations?.address) {
				return showToast('warning', 'Please select your location', 1000);
			}

			setisLoadingDeliveryList(true);

			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			};

			const dataPost = {
				unquieid: values?.unquieid,
				customerID: values?.customerID,
				sender: values?.sender,
				origin: values?.origin,
				destinations: values?.destinations,
			};

			const url = `${apiUrl}deliverypartners/loadPartnersList`;
			const res = await axios.post(url, JSON.stringify(dataPost), config);

			if (res?.data?.status === 'ok' && res?.data?.result?.length) {
				const { delivery_fee, delivery_subsidies } = res?.data || {};
				setValues((prev) => {
					return {
						...prev,
						deliveryFee: Number(delivery_fee) || 0,
						deliverySubsidies: Number(delivery_subsidies) || 0,
					};
				});
				const result = res?.data?.result || [];
				const normalizedVehicleData = result?.map((item) => {
					item.vehicle = item.vehicle.map((vehicle) => {
						if (vehicle.vehicleType === 'MOTORCYCLE') {
							vehicle.vehicleType = 'bike';
						}
						if (vehicle.vehicleType === 'TRUCK175') {
							vehicle.vehicleType = 'Truck';
						}
						return vehicle;
					});
					return item;
				});
				const serviceList = normalizedVehicleData
					?.map((item) => item.vehicle)
					.flat()
					.reduce((acc, vehicle) => {
						const existingService = acc.find((service) => service.name === vehicle.name && service.serviceType === vehicle.serviceType);

						if (existingService) {
							existingService.vehicle.push(vehicle);
						} else {
							acc.push({
								name: vehicle.name,
								serviceType: vehicle.serviceType,
								vehicle: [vehicle],
							});
						}

						return acc;
					}, [])
					.sort((a, b) => {
						if (a.name === 'UFood') return -1;
						if (b.name === 'UFood') return 1;
						return 0;
					});
				dispatch(
					setCart({
						pathnersList: serviceList,
					}),
				);
			} else {
				dispatch(
					setCart({
						pathnersList: [],
					}),
				);
				setIsShowBottomSheet(true);
				setIsTooFar(true);
			}
			setisLoadingDeliveryList(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingDeliveryList(false);
		}
	};

	const handleGetPaymentReference = async () => {
		try {
			console.log('handleGetPaymentReference');
			setisLoadingCart(true);
			const postData = {
				unquieid: unquieID,
				customerID: customerID,
			};

			const url = `${apiUrl}Paymentuvcr/generatePaymentRefrence`;

			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const reference = res?.data?.paymentRefrence || '';
				console.log('reference: ', reference);
				setValues((prev) => {
					return {
						...prev,
						paymentReference: reference,
					};
				});
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const refreshToken = async () => {
		console.log('refreshToken');
		try {
			const response = await axios.post(`${apiUrl}auth/refresh-token`, {
				refresh_token: refresh_token,
				customer_id: decodeValue(customerID),
			});

			if (response?.status === 200 && response?.data?.status === 'ok') {
				const data = response?.data?.data;
				dispatch(
					setUser({
						token: data?.token,
					}),
				);
			} else {
				showToast('error', 'please relogin', 1000);
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	useEffect(() => {
		dispatch(clearPayment());
		handleGetCart();
		handleGetPaymentReference();
		handleGetUVBalance();
		refreshToken();
	}, []);

	useEffect(() => {
		if (!cart) return;
		console.log('use effect origin & items');
		handleGetVoucherList(cart);
		handleGetPaymentFee();
		handleGetStoreDetail();
		setValues((prev) => {
			return {
				...prev,
				origin: {
					address: cart?.store?.storeAddress || '',
					coordinate: cart?.store?.coordinate || {},
					village: cart?.store?.village || '',
					district: cart?.store?.district || '',
					city: cart?.store?.city || '',
					province: cart?.store?.province || '',
					postalCode: cart?.store?.postalCode || '',
					keywords: cart?.store?.storeName || '',
				},
				destinations: {
					...prev.destinations,
					items: cart?.item || [],
				},
			};
		});
	}, [cart]);

	useEffect(() => {
		if (delivery?.vehicle?.length === 1 || !isLoadingDeliveryList) {
			console.log('use effect set delivery');
			setValues((prev) => {
				return {
					...prev,
					deliveryType: delivery?.vehicle?.[0] || null,
				};
			});
		}
	}, [delivery, isLoadingDeliveryList]);

	useEffect(() => {
		if (!address) return;
		console.log('use effect set address');
		setValues((prev) => {
			return {
				...prev,
				destinations: {
					...prev.destinations,
					address: address,
					coordinate: {
						latitude: latitude,
						longitude: longitude,
					},
					village: village,
					district: district,
					city: city,
					province: state,
					postalCode: postalCode,
				},
			};
		});
	}, [address]);

	useEffect(() => {
		if (isEmptyData(values.origin?.address) || isEmptyData(values.destinations?.address) || values.destinations?.items?.length === 0) return;
		console.log('use effect get pathner list');
		handleGetPathnerList();
	}, [values.origin?.address, values.destinations?.address, values.destinations?.items]);

	const handleGetProductDetail = async (slug, data) => {
		console.log('handleGetProductDetail', slug);
		try {
			setisLoadingCart(true);
			const url = `${apiUrl}catalogs/productdetails?unquieid=${unquieID}&productID=${slug}`;
			const res = await axios.get(url, {
				headers: {
					Accept: 'application/json, text/plain, */*',
				},
			});
			if (res.data?.status === 'ok') {
				const product = res.data?.result || null;
				console.log('cart', cart);
				console.log('product: ', product);
				setCartItem(product);
				setProduct(product);
				setItem(data);
				setIsShowBottomSheet(true);
				if (product?.product_type === '1') {
					setIsShowSimpleProduct(true);
				}
				if (product?.product_type === '2') {
					setIsShowComboProduct(true);
				}
			}
			if (res?.data?.status === 'error') {
				showToast('warning', 'Product not found', 1000);
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	};

	const handleFavorite = async () => {
		console.log('handleFavorite');
		try {
			const dataPost = {
				unquieid: unquieID,
				shopID: encodeValue(cart?.store?.storeID),
				customerID: encodeValue(customerID),
			};
			const url = `${apiUrl}store/${Boolean(Number(store?.favorite || store?.favourite)) ? 'removeFavourite' : 'addFavourite'}`;
			const res = await axios.post(url, qs.stringify(dataPost));
			if (res?.data?.status === 'ok') {
				handleGetStoreDetail();
			}
		} catch (error) {
			console.log('error: ', error);
		}
	};

	const groupByNominal = async (b2bVoucherList = []) => {
		console.log('groupByNominal');
		const result = [];

		b2bVoucherList?.forEach((voucher) => {
			const existingVoucher = result.find((v) => v.nominal === voucher.nominal);

			if (existingVoucher) {
				existingVoucher.total_is_use += voucher.is_use ? 1 : 0;
				existingVoucher.total_stock_available += voucher.stock_available;
			} else {
				result.push({
					...voucher,
					total_is_use: voucher.is_use ? 1 : 0,
					total_stock_available: voucher.stock_available,
				});
			}
		});

		return result;
	};

	const autoApplyMyVoucher = async (voucherMy = []) => {
		console.log('autoApplyMyVoucher');

		const myVouchersList = [];

		let totalMyNominal = 0;

		const cartSubTotal = cart?.subTotal || 0;

		for (let my of voucherMy || []) {
			let isUse = my?.is_use ?? false;

			if (totalMyNominal + my?.nominal_voucher <= cartSubTotal) {
				totalMyNominal += my?.nominal_voucher;
				isUse = true;
			}
			myVouchersList?.push({ ...my, is_use: isUse });
		}

		const listUseMyVoucher = myVouchersList?.filter((voucher) => voucher.is_use)?.map((voucher) => voucher?.id);

		return {
			myVouchersList: myVouchersList || [],
			totalMyNominal: totalMyNominal || 0,
			listUseMyVoucher: listUseMyVoucher || [],
		};
	};

	const autoApplyB2BVoucher = async (voucherB2B = [], totalMyNominal = 0) => {
		console.log('autoApplyB2BVoucher');

		const b2bVoucherList = [];
		let totalB2BNominal = 0;
		let totalB2BPrice = 0;

		let remainingNominal = Math.max(0, cart?.subTotal - totalMyNominal);

		for (let b2b of voucherB2B) {
			let totalIsUse = 0;
			const availableStock = b2b?.total_stock_available || 0;
			const maxNominalUsage = Math.floor(remainingNominal / b2b.nominal);

			totalIsUse = Math.min(availableStock, maxNominalUsage);

			if (totalIsUse > 0) {
				totalB2BNominal += totalIsUse * b2b.nominal;
				totalB2BPrice += totalIsUse * b2b.price_user;
				remainingNominal -= totalIsUse * b2b.nominal;
			}

			b2bVoucherList.push({ ...b2b, total_is_use: totalIsUse });
		}

		const listUseB2BVoucher = b2bVoucherList?.filter((voucher) => voucher.total_is_use > 0)?.map((voucher) => voucher?.code);

		return {
			b2bVoucherList: applyB2BVoucher ? b2bVoucherList : voucherB2B || [],
			totalB2BNominal: totalB2BNominal || 0,
			totalB2BPrice: totalB2BPrice || 0,
			listUseB2BVoucher: listUseB2BVoucher || [],
		};
	};

	const handleApplyB2BVoucher = () => {
		console.log('handleApplyB2BVoucher');

		const b2bList = [];
		let totalB2BNominal = 0;
		let totalB2BPrice = 0;

		let remainingNominal = Math.max(0, cart?.subTotal - paymentSummary?.myVoucherApplied);

		console.log('remainingNominal: ', remainingNominal);

		for (let b2b of b2bVoucherList) {
			let totalIsUse = 0;
			const availableStock = b2b?.total_stock_available || 0;
			const maxNominalUsage = Math.floor(remainingNominal / b2b.nominal);

			totalIsUse = Math.min(availableStock, maxNominalUsage);

			if (totalIsUse > 0) {
				totalB2BNominal += totalIsUse * b2b.nominal;
				totalB2BPrice += totalIsUse * b2b.price_user;
				remainingNominal -= totalIsUse * b2b.nominal;
			}

			b2bList.push({ ...b2b, total_is_use: totalIsUse });
		}

		const b2BVoucherSaved = Math.max(0, totalB2BNominal - totalB2BPrice);
		console.log('b2BVoucherSaved: ', b2BVoucherSaved);

		dispatch(
			setVoucher({
				applyB2BVoucher: true,
				b2BVoucherSaved: b2BVoucherSaved || 0,
				b2bVoucherList: b2bList || [],
				totalB2BNominal: totalB2BNominal || 0,
				totalB2BPrice: totalB2BPrice || 0,
			}),
		);
	};

	const handleGetVoucherList = async (cart) => {
		console.log('handleGetVoucherList');
		try {
			setisLoadingVoucherList(true);

			let voucherMyGrouping = [];
			let totalMyVoucher = 0;
			let voucherMy = [];
			let voucherB2B = [];

			const postDataMy = {
				posID: cart?.store?.posID ?? '',
				token: token,
				refresh_token: refresh_token,
			};
			const myUrl = `${apiUrl}voucher/list`;
			const resMyLimit = await axios.post(myUrl, qs.stringify(postDataMy));
			if (resMyLimit?.data?.status === 'ok') {
				voucherMyGrouping = resMyLimit?.data?.data || [];
				voucherMyGrouping?.forEach((voucher) => {
					totalMyVoucher += Number(voucher?.total_voucher) || 0;
				});
			}

			if (totalMyVoucher > 0) {
				postDataMy.page = 1;
				postDataMy.limit = totalMyVoucher;
				const resMy = await axios.post(myUrl, qs.stringify(postDataMy));
				if (resMy?.data?.status === 'ok') {
					voucherMy = resMy?.data?.data || [];
				}
			}

			const sortedMyVouchers = voucherMy?.sort((a, b) => new Date(a.expired_date) - new Date(b.expired_date));

			const postDataB2B = {
				merchantCode: cart?.store?.merchantCode ?? '',
				page: 1,
				limit: 10,
			};
			const b2bUrl = `${apiUrl}voucher-b2b/list`;
			const resB2B = await axios.post(b2bUrl, qs.stringify(postDataB2B));
			if (resB2B?.data?.status === 'ok') {
				voucherB2B = resB2B?.data?.data || [];
			}

			const autoApplyMy = await autoApplyMyVoucher(sortedMyVouchers);

			const groupB2BVoucher = await groupByNominal(voucherB2B);
			const autoApplyB2b = await autoApplyB2BVoucher(groupB2BVoucher, autoApplyMy?.totalMyNominal);
			const sortedB2BVouchers = autoApplyB2b?.b2bVoucherList?.sort((a, b) => a.nominal - b.nominal);

			dispatch(
				setVoucher({
					myVoucherList: autoApplyMy?.myVouchersList || [],
					myVoucherNominal: autoApplyMy?.totalMyNominal || 0,
					listUseMyVoucher: autoApplyMy?.listUseMyVoucher || [],

					b2bVoucherList: sortedB2BVouchers || [],
					b2bVoucherNominal: autoApplyB2b?.totalB2BNominal || 0,
					b2BVoucherPrice: autoApplyB2b?.totalB2BPrice || 0,
					b2BVoucherSaved: Math.max(0, autoApplyB2b?.totalB2BNominal - autoApplyB2b?.totalB2BPrice),
					applyB2BVoucher: false,
					customApplyVoucher: false,
					listUseB2BVoucher: autoApplyB2b?.listUseB2BVoucher || [],
				}),
			);

			setisLoadingVoucherList(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingVoucherList(false);
		}
	};

	const handleUpdateVoucherList = (id, voucher_type, use = false, type = 'plus') => {
		console.log('handleUpdateVoucherList', id, voucher_type, use, type);
		const updateMyVoucher = (voucherArray) => {
			console.log('updateMyVoucher');
			return voucherArray.map((voucher) => {
				let isUse = voucher.is_use ?? false;
				if (voucher.id === id) {
					isUse = !use;
				}
				return {
					...voucher,
					is_use: isUse,
				};
			});
		};

		const updateB2BVoucher = (voucherArray) => {
			console.log('updateB2BVoucher');
			return voucherArray.map((voucher) => {
				let totalIsUse = voucher.total_is_use || 0;
				if (voucher.code === id && type === 'plus') {
					totalIsUse += 1;
				}
				if (voucher.code === id && type === 'minus') {
					totalIsUse -= 1;
				}
				return {
					...voucher,
					total_is_use: Math.max(0, totalIsUse),
				};
			});
		};

		if (voucher_type === 'b2b_voucher') {
			const updatedList = updateB2BVoucher(b2bVoucherList);
			const extractMyVouchers = updatedList
				?.filter((voucher) => voucher.total_is_use)
				?.map((voucher) => ({
					total_is_use: voucher.total_is_use,
					nominal: voucher.nominal,
					price_user: voucher.price_user,
				}));
			const totalB2BNominal = extractMyVouchers?.reduce((sum, voucher) => {
				return sum + voucher.total_is_use * voucher.nominal;
			}, 0);
			const totalB2BPrice = extractMyVouchers?.reduce((sum, voucher) => {
				return sum + voucher.total_is_use * voucher.price_user;
			}, 0);
			dispatch(
				setVoucher({
					b2bVoucherList: updatedList,
					b2bVoucherNominal: totalB2BNominal,
					b2BVoucherPrice: totalB2BPrice,
					totalB2BSaved: Math.max(0, totalB2BNominal - totalB2BPrice),
					customApplyVoucher: true,
				}),
			);
		} else if (voucher_type === 'my_voucher') {
			const updatedList = updateMyVoucher(myVoucherList);
			const extractMyVouchers = updatedList
				?.filter((voucher) => voucher.is_use)
				?.map((voucher) => ({
					nominal: voucher.nominal_voucher,
				}));
			const totalMyNominal = extractMyVouchers?.reduce((sum, voucher) => sum + voucher.nominal, 0);
			dispatch(
				setVoucher({
					myVoucherList: updatedList,
					myVoucherNominal: totalMyNominal,
					customApplyVoucher: true,
				}),
			);
		}
	};

	const handleGetUVBalance = async () => {
		console.log('handleGetUVBalance');
		try {
			setIsLoadingUvBalance(true);
			const postData = {
				token: token,
				refresh_token: refresh_token,
			};
			const url = `${apiUrl}uv-balance`;
			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const result = res?.data?.data || {};

				setUVBalance({
					code: 'ULTRA-VOUCHER',
					name: result?.name ?? 'Ultra Value',
					value: result?.total_value || 0,
					image: result?.image?.url || '',
				});
			}
			setIsLoadingUvBalance(false);
		} catch (error) {
			console.log('error: ', error);
			setIsLoadingUvBalance(false);
		}
	};

	const paymentSummary = useMemo(() => {
		const subTotal = Number(cart?.subTotal || 0);
		const packagingFee = Number(cart?.packagingFee || 0);
		const platformFee = Number(cart?.platformFee || 0);
		const deliveryFee = Number(values?.deliveryType?.amount || 0) + Number(values?.deliveryFee);
		const paymentFee = Number(values?.paymentType?.fee || 0);

		let totalNominalVoucher = myVoucherNominal || 0;
		if (applyB2BVoucher || customApplyVoucher) {
			totalNominalVoucher = totalNominalVoucher + (applyB2BVoucher || customApplyVoucher ? b2bVoucherNominal : 0);
			// Math.max(0, b2bVoucherNominal - b2BVoucherPrice) : 0);
		}

		const isDisableVoucher = totalNominalVoucher > subTotal;

		const fixTotalMyVoucherNominal = isDisableVoucher ? subTotal : totalNominalVoucher;

		const totalPayment = subTotal + packagingFee + platformFee + deliveryFee + paymentFee + (applyB2BVoucher || customApplyVoucher ? b2BVoucherPrice : 0) - fixTotalMyVoucherNominal;

		const otherFee = packagingFee + platformFee + paymentFee;

		return {
			subTotal: subTotal,
			totalPayment: totalPayment,
			deliveryFee: deliveryFee,
			myVoucherApplied: fixTotalMyVoucherNominal,
			otherFee: otherFee,
			packagingFee: packagingFee,
			platformFee: platformFee,
			paymentFee: paymentFee,
			isDisableVoucher: isDisableVoucher,
		};
	}, [cart, values, myVoucherNominal, b2bVoucherNominal, b2BVoucherPrice, applyB2BVoucher, customApplyVoucher]);

	const handleGetPaymentFee = useCallback(async () => {
		console.log('handleGetPaymentFee');
		try {
			setisLoadingCart(true);
			const postData = {
				token: token,
				refresh_token: refresh_token,
				totalAmount: paymentSummary.totalPayment,
			};
			const url = `${apiUrl}calculate-fee`;
			const res = await axios.post(url, qs.stringify(postData));
			if (res?.data?.status === 'ok') {
				const result = res?.data?.data || [];

				let currentPaymentTypeFee = 0;

				const updatedPaymentMethodList = paymentMethodList?.map((paymentCategory) => {
					const updatedPaymentMethods = paymentCategory.payment_method.map((method) => {
						const matchedCategory = result.find((item) => item.payment_method.some((pm) => pm.code === method.code));

						if (matchedCategory) {
							const matchedMethod = matchedCategory.payment_method.find((pm) => pm.code === method.code);
							if (matchedMethod.code === values?.paymentType?.code && !currentPaymentTypeFee) {
								currentPaymentTypeFee = matchedMethod?.fee;
							}
							return {
								...method,
								fee: matchedMethod?.fee || method.fee,
							};
						}
						return method;
					});

					return {
						...paymentCategory,
						payment_method: updatedPaymentMethods,
					};
				});

				dispatch(
					setCart({
						paymentMethodList: updatedPaymentMethodList,
					}),
				);
				setValues((prev) => {
					return {
						...prev,
						paymentType: {
							...prev.paymentType,
							fee: currentPaymentTypeFee,
						},
					};
				});
			}
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			setisLoadingCart(false);
		}
	}, [paymentSummary]);

	const processB2BVouchers = (b2bVoucherList = []) => {
		let result = [];

		b2bVoucherList.forEach((voucher) => {
			if (voucher.total_is_use) {
				for (let i = 0; i < voucher.total_is_use; i++) {
					let newVoucher = { ...voucher };
					delete newVoucher.total_is_use;
					delete newVoucher.total_stock_available;
					result.push({
						voucher_code: newVoucher?.code,
						voucher_nominal: newVoucher?.nominal,
						voucher_price: newVoucher?.price,
						voucher_merchant_code: newVoucher?.merchant_code,
						voucher_qty: 1,
						voucher_price_user: newVoucher?.price_user,
					});
				}
			}
		});

		return result;
	};

	const handlePayment = async (values, resetForm) => {
		console.log('handlePayment');
		try {
			setisLoadingCart(true);

			const myVoucher = myVoucherList?.filter((voucher) => voucher.is_use)?.map((voucher) => voucher?.voucher_id) || [];

			const b2bVoucher = processB2BVouchers(b2bVoucherList);

			const dataPost = {
				unquieid: values?.unquieid,
				customerID: values?.customerID,
				deliveryCharge: values?.deliveryType?.amount || 0,
				additionalDeliveryCharge: 0,
				taxCharge: 0,
				taxAmount: 0,
				subTotal: paymentSummary?.subTotal,
				grandTotal: paymentSummary?.totalPayment,
				totalDiscount: 0,
				discountType: '',
				totalItem: values?.items?.length || 0,
				instantOrder: 'Yes',
				availabilityID: deliveryId,
				availabilityName: 'Delivery',
				orderSource: 'Web',
				firstName: values?.destinations?.recipient?.firstName || '',
				email: values?.destinations?.recipient?.email || '',
				phone: values?.destinations?.recipient?.phone || '',
				lastName: values?.destinations?.recipient?.lastName || '',
				address: values?.destinations?.address || '',
				village: values?.destinations?.village || '',
				district: values?.destinations?.district || '',
				city: values?.destinations?.city || '',
				state: values?.destinations?.state || '',
				country: values?.destinations?.country || '',
				latitude: values?.destinations?.coordinate?.latitude || '',
				longitude: values?.destinations?.coordinate?.longitude || '',
				billingaddress: cart?.store?.storeAddress,
				storeID: cart?.store?.storeID,
				items: values?.destinations?.items || [],
				deliveryPartner: values?.deliveryType,
				siteURL: `${process.env.REACT_APP_BASE_URL}payment`,
				failedURL: `${process.env.REACT_APP_BASE_URL}summary?paymentstatus=failed`,
				paymentFee: values?.paymentType.fee || 0,
				paymentMethod: values?.paymentType.code || '',
				paymentGetway: 'SNAP',
				paymentReferenceID: values?.paymentReference || null,
				validateOrder: 'Yes',
				deliveryZone: '',
				packagingFee: paymentSummary?.packagingFee,
				platformFee: paymentSummary?.platformFee,
				deliveryFee: values?.deliveryFee,
				subsidy: values?.deliveryType?.subsidy || false,
				deliverySubsidies: values?.deliverySubsidies,
				token: token,
				refresh_token: refresh_token,
				b2b_voucher: b2bVoucher,
				my_voucher: myVoucher,
			};
			console.log('dataPost: ', dataPost);
			const config = {
				headers: {
					Accept: 'application/json, text/plain, */*',
					'Content-Type': 'application/json',
				},
			};

			const urlDelivery = `${apiUrl}deliverypartners/createPartnerOrder`;

			const orderDelivery = await axios.post(urlDelivery, JSON.stringify(dataPost), config);
			if (orderDelivery?.data?.status !== 'ok') {
				console.log(orderDelivery);
				showToast('error', orderDelivery?.data?.message || '', 1000);
				handleGetPathnerList();
				return setisLoadingCart(false);
			}

			dataPost.deliveryOrderID = orderDelivery?.data?.deliveryOrderID;

			const urlPayment = `${apiUrl}paymentuvcr/processPayment`;

			const createPayment = await axios.post(urlPayment, JSON.stringify(dataPost), config);
			if (createPayment?.data?.status !== 'ok') {
				console.log(createPayment);
				showToast('error', createPayment?.data?.message || '', 1000);
				return setisLoadingCart(false);
			}
			dispatch(
				setPayment({
					session_token: createPayment?.data?.session_token || null,
					data: createPayment?.data?.data,
					date: new Date(),
					timeLeft: 15 * 60,
					status: null,
				}),
			);
			dispatch(
				setVoucher({
					applyB2BVoucher: false,
					customApplyVoucher: false,
				}),
			);

			history.push('/payment');
			setisLoadingCart(false);
		} catch (error) {
			console.log('error: ', error);
			showToast('error', error?.message ?? '');
			setisLoadingCart(false);
		}
	};

	const handleVoucherDetail = (isShow, data) => {
		if (isShow) {
			setShowVoucherDetail({
				isOpen: true,
				data,
			});
		} else {
			setShowVoucherDetail({
				isOpen: false,
				data: null,
			});
		}
	};

	const groupMyForTooltip = (myVoucherList = []) => {
		const listUseMyVoucher = myVoucherList?.filter((voucher) => voucher.is_use) || [];

		const result = [];

		listUseMyVoucher?.forEach((voucher) => {
			const existingVoucher = result.find((v) => v.nominal_voucher === voucher.nominal_voucher);

			if (existingVoucher) {
				existingVoucher.total_is_use += 1;
			} else {
				result.push({
					name: voucher?.name,
					desc: 'Using the available voucher in my voucher.',
					total: 1,
					price: voucher?.nominal_voucher,
				});
			}
		});

		return result;
	};

	const groupB2BForTooltip = (b2bVoucherList = []) => {
		console.log('groupB2BForTooltip');
		const listUseB2BVoucher = b2bVoucherList?.filter((voucher) => voucher.total_is_use) || [];

		const result = [];

		listUseB2BVoucher?.forEach((voucher) => {
			const existingVoucher = result.find((v) => v.nominal === voucher.nominal);

			if (existingVoucher) {
				existingVoucher.total += voucher?.total_is_use;
			} else {
				result.push({
					name: voucher?.name,
					desc: 'Purchase voucher available at related merchants.',
					total: voucher?.total_is_use,
					nominal: voucher?.nominal,
				});
			}
		});

		return result;
	};

	const tooltipVoucherList = useMemo(() => {
		const groupMyVoucher = groupMyForTooltip(myVoucherList);
		const groupB2BVoucher = groupB2BForTooltip(b2bVoucherList);
		return [...groupMyVoucher, ...groupB2BVoucher];
	}, [myVoucherList, b2bVoucherList]);

	const tooltipOtherFee = useMemo(() => {
		const result = [];
		if (paymentSummary?.packagingFee) {
			result?.push({
				name: 'Packaging Fee',
				desc: 'Charge for packaging materials ensuring safe and hygienic food delivery.',
				total: null,
				nominal: paymentSummary?.packagingFee,
			});
		}
		if (paymentSummary?.platformFee) {
			result?.push({
				name: 'Platform Fee',
				desc: 'Fee for facilitating transactions via our integrated food delivery platform.',
				total: null,
				nominal: paymentSummary?.platformFee,
			});
		}
		if (paymentSummary?.paymentFee) {
			result?.push({
				name: 'Service Fee',
				desc: 'Service fee are applied to provide the best service for Ufood customers.',
				total: null,
				nominal: paymentSummary?.paymentFee,
			});
		}
		return result;
	}, [paymentSummary]);

	const handleCloseBottomSheet = () => {
		console.log('handleCloseBottomSheet');
		setIsShowBottomSheet(false);
		setIsShowDelivery(false);
		setIsShowPaymentMethod(false);
		setIsShowSimpleProduct(false);
		setIsShowComboProduct(false);
		setIsShowVoucher(false);
		setIsShowMoreVoucher(false);
		setIsShowDeliveryLocation(false);
	};

	return (
		<>
			{isLoadingCart && <Loader overlay />}
			<div className='h-screen w-full flex justify-center items-center bg-white'>
				<div className='relative h-screen w-full max-w-lg'>
					<div className={`absolute mt-5 w-full`}>
						<div className='flex flex-row justify-between mx-4'>
							<div
								className='flex justify-center items-center cursor-pointer'
								onClick={() => (isEmptyData(store?.storeSlug) ? history.goBack() : history.push(`/outlet/${store?.storeSlug}`))}>
								<ArrowLeftIcon className='h-8 w-8 text-gray-900 p-1.5' />
								<div className='pl-2 text-base text-gray-900 font-semibold truncate max-w-72'>{cart?.store?.storeName ?? ''}</div>
							</div>
							<div className='flex gap-2'>
								<div className='flex justify-center items-center cursor-pointer' onClick={handleFavorite}>
									<HeartIcon className={`h-8 w-8 ${Boolean(Number(store?.favorite || store?.favourite)) ? 'fill-red-500 text-red-500' : 'text-gray-900'} p-1.5`} />
								</div>
							</div>
						</div>
						<div className='border-b-1 border-solid pb-2 border-gray-200' />
						<div className={`mx-4 mt-4 flex justify-between items-center`}>
							<div>
								<div className='text-xs text-gray-900 font-semibold'>Delivery Location</div>
								<div className='text-sm text-gray-900 font-semibold pt-2 max-w-64 overflow-ellipsis line-clamp-2'>{values?.destinations?.address}</div>
							</div>
							<button
								className={`${
									errors?.destinations?.address && touched?.destinations?.address ? 'border-red-500 text-red-500' : 'border-rose-violet text-rose-violet'
								} bg-white border-solid border-1 py-2 px-4 text-xs font-semibold rounded-md min-w-fit`}
								// history.push('/google-map?back=summary')
								onClick={() => {
									setIsShowBottomSheet(true);
									setIsShowDeliveryLocation(true);
								}}>
								Change
							</button>
						</div>
						<div className='mx-4 mt-4'>
							<div className='flex items-center text-center'>
								<DoubleNoteIcon />
								<div className='text-xs text-gray-900 font-semibold pl-2'>Delivery Notes</div>
								<div className='text-xs text-gray-400 font-thin pl-2'>Optional</div>
							</div>
							<textarea
								className='mt-2 py-2.5 px-4 text-xs placeholder:text-xs placeholder:text-gray-400 border-1 border-solid border-gray-300 bg-white h-auto'
								rows={1}
								defaultValue={values?.notes ?? ''}
								onChange={(e) => {
									setValues((prev) => {
										return {
											...prev,
											notes: e.target.value ?? '',
										};
									});
									dispatch(
										setCart({
											notes: e.target.value,
										}),
									);
								}}
								placeholder='e.g. Please leave food at the door'
							/>
						</div>
						<div className='border-b-8 border-solid pb-4 border-gray-100' />
						<div className='mx-4 mt-4'>
							<div className='text-gray-900 text-xs font-semibold'>Delivery Option</div>
							<div className='mt-2'>
								<div className={`border-gray-300 border-1 border-solid rounded-md flex justify-between px-2 py-2.5`}>
									<div className='flex items-center'>
										<MotorbikeIcon />
										<div className='pl-2 text-gray-900 text-sm font-semibold'>{delivery?.name ? `${delivery?.name} ${delivery?.serviceType}` : 'Choose Delivery'}</div>
									</div>
									<button
										className={`${
											errors?.deliveryType && touched?.deliveryType
												? 'border-red-500 text-red-500'
												: isLoadingDeliveryList
												? 'border-gray-300 text-rose-violet'
												: 'border-rose-violet text-rose-violet'
										} bg-white border-solid border-1 py-2 px-4 text-xs font-semibold rounded-md min-w-[80px] flex justify-center items-center`}
										disabled={isLoadingDeliveryList}
										onClick={() => {
											setIsShowDelivery(true);
											setIsShowBottomSheet(true);
										}}>
										{isLoadingDeliveryList ? <LoaderIcon /> : delivery?.name ? 'Change' : 'Choose'}
									</button>
								</div>
								{delivery?.vehicle?.map((type, index) => {
									return (
										<div
											key={`type_${index}`}
											className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center ${
												type?.vehicleType === values?.deliveryType?.vehicleType ? '' : 'bg-gray-100'
											}`}
											onClick={() => {
												setValues((prev) => {
													return {
														...prev,
														deliveryType: type,
													};
												});
											}}>
											<div className=''>
												<div className={`text-sm capitalize ${type?.vehicleType === values?.deliveryType?.vehicleType ? 'text-gray-900' : 'text-gray-500'} font-semibold`}>
													{type?.vehicleType}
												</div>
												<div className='text-xs text-gray-400 pt-1 font-thin'>20-30 minute . Max 20 kg</div>
											</div>
											<div className='flex'>
												<div className='text-gray-500 font-normal text-sm line-through hidden'>Rp6.000</div>
												<div className={`pl-2 ${type?.vehicleType === values?.deliveryType?.vehicleType ? 'text-gray-900' : 'text-gray-500'} font-semibold text-sm`}>
													{formatRupiah((type?.amount || 0) + values?.deliveryFee)}
												</div>
												<div className='pl-2'>
													<input
														id='transport'
														type='radio'
														name='transport'
														value='bike1'
														checked={type?.vehicleType === values?.deliveryType?.vehicleType}
														onChange={() => null}
														className={`${
															type?.vehicleType === values?.deliveryType?.vehicleType ? '!border-rose-violet' : '!border-gray-400 !bg-gray-200'
														} after:!bg-rose-violet active:bg-white focus:bg-transparent`}
													/>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						<div className='border-b-8 border-solid pb-4 border-gray-100' />
						<div className='mx-4 mt-4'>
							<div className='text-gray-900 text-sm font-semibold'>Order Summary</div>
							<div className='mt-4'>
								{cart?.item?.map((item, index) => {
									return <CartDetail data={item} handleGetProductDetail={handleGetProductDetail} handleCart={handleCart} />;
								})}
							</div>
						</div>
						<div className='border-b-1 border-solid pb-2 border-gray-200' />
						<div className='flex justify-between mx-4 my-4 items-center'>
							<div>
								<div className='text-sm text-gray-900 font-semibold'>Add More?</div>
								<div className='text-xs text-gray-500 pt-1 font-thin'>Need anything else? add other dishes</div>
							</div>
							<button
								className='bg-white border-solid border-1 border-rose-violet py-1.5 px-6 text-xs font-semibold text-rose-violet rounded-md min-w-fit'
								onClick={() => history.push(`/outlet/${store?.storeSlug}`)}>
								Add
							</button>
						</div>
						<div className='border-b-1 border-solid pb-2 border-gray-200' />
						<div className='w-full h-full flex flex-col my-4'>
							<div className='mx-4 flex flex-col border-1 border-solid border-gray-200 rounded-xl'>
								<div className='flex flex-row justify-between relative min-h-16 h-fit items-center'>
									{isLoadingVoucherList ? (
										<div className='z-[1] w-full flex items-center justify-center'>
											<LoaderIcon />
										</div>
									) : (
										<div className='flex flex-row pl-2 items-center z-[1]'>
											<AutoVoucherIcon />
											<div className='pl-2 h-full'>
												{myVoucherNominal || (b2bVoucherNominal && applyB2BVoucher) ? (
													<div className='text-white font-normal text-sm break-words'>Great, voucher was applied</div>
												) : (
													<div className='text-white font-normal text-sm break-words'>Add your voucher here</div>
												)}
												{myVoucherNominal || (b2bVoucherNominal && !applyB2BVoucher) ? (
													<div className='text-white font-thin text-xxs pt-1 break-words'>Total voucher worth {formatRupiah(myVoucherNominal || b2bVoucherNominal)}</div>
												) : (
													<div className='text-white font-thin text-xxs pt-1 break-words'>Maximize discount with these vouchers now</div>
												)}
											</div>
										</div>
									)}
									{myVoucherNominal && !isLoadingVoucherList ? (
										<CheckCircleIcon className={`h-7 w-7 z-[1] mr-4 text-green-600 `} />
									) : applyB2BVoucher && !isLoadingVoucherList ? (
										<CheckCircleIcon className={`h-7 w-7 z-[1] mr-4 text-white `} />
									) : b2bVoucherList?.length && !isLoadingVoucherList ? (
										<button
											onClick={() => {
												console.log('no my voucher && apply b2b voucher');
												if (isLoadingCart) return;
												handleApplyB2BVoucher();
											}}
											disabled={isLoadingCart}
											className='bg-white px-4 py-2 mr-4 rounded-lg border-rose-violet text-rose-violet font-semibold min-w-fit'>
											Apply
										</button>
									) : null}
									<img src={`${myVoucherNominal ? './assets/img/bg-my-voucher.png' : './assets/img/bg-b2b-voucher.png'}`} className='h-full w-full absolute rounded-lg z-0' />
								</div>
								<div className='flex justify-between items-center my-4 mx-4'>
									{isLoadingVoucherList ? (
										<div className='w-full flex items-center justify-center'>
											<LoaderIcon />
										</div>
									) : (
										<div className='text-gray-600 font-semibold text-sm'>
											{b2BVoucherSaved && myVoucherNominal && !applyB2BVoucher ? `Get ${formatRupiah(b2BVoucherSaved)} discount just for you!` : `Check more vouchers`}
										</div>
									)}
									{b2BVoucherSaved && myVoucherNominal && !applyB2BVoucher && !isLoadingVoucherList ? (
										b2bVoucherList?.length ? (
											<button
												onClick={() => {
													console.log('have my voucher && apply b2b voucher');
													if (isLoadingCart) return;
													handleApplyB2BVoucher();
												}}
												disabled={isLoadingCart}
												className='bg-white px-4 py-2 rounded-lg border-rose-violet text-rose-violet font-semibold'>
												Apply
											</button>
										) : null
									) : (
										!isLoadingCart && !isLoadingVoucherList && (
											<ArrowRightIcon
												class='h-6 w-6 text-gray-700'
												onClick={() => {
													console.log('show all voucher');
													setIsShowBottomSheet(true);
													setIsShowVoucher(true);
												}}
											/>
										)
									)}
								</div>
							</div>
						</div>
						<div className='mx-4 mt-4'>
							<div className='text-gray-900 text-sm font-semibold'>Payment Method</div>
							<div
								className='mt-2 border-solid border-1 border-gray-300 rounded-lg px-4 py-2 flex justify-between items-center h-14'
								onClick={() => {
									setIsShowPaymentMethod(true);
									setIsShowBottomSheet(true);
								}}>
								<div className='flex items-center'>
									{values?.paymentType.code === 'ULTRA-VOUCHER' ? (
										<UltraVoucherIcon />
									) : (
										<img alt={values?.paymentType?.alt ?? ''} src={values?.paymentType?.image_url} className='w-9 h-auto' />
									)}
									<div className='pl-2'>
										<div className='text-gray-700 text-xs font-thin'>{values?.paymentType?.name ?? ''}</div>
										{values?.paymentType.code === 'ULTRA-VOUCHER' && <div className='text-gray-900 text-sm font-semibold'>{formatRupiah(UVBalance?.value || 0)}</div>}
									</div>
								</div>
								<ChevronRightIcon className='h-4 w-4 text-gray-800' strokeWidth={3} />
							</div>
						</div>
						<div className='mx-4 mt-4 mb-28'>
							<div className='text-gray-900 text-sm font-semibold'>Payment Summary</div>
							<div className='mt-4 border-solid border-1 border-gray-300 rounded-lg px-4 py-2.5'>
								<div className={`flex justify-between items-center`}>
									<div className='text-gray-900 text-xs font-normal'>F&B Price</div>
									<div className='text-xs font-normal flex'>
										<div className='text-gray-400 font-thin line-through hidden'>Rp210.000</div>
										<div className='text-rose-violet pl-2'>{formatRupiah(paymentSummary?.subTotal || 0)}</div>
									</div>
								</div>
								<div className={`${!paymentSummary?.deliveryFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
									<div className='text-gray-900 text-xs font-normal'>Delivery</div>
									<div className='text-xs font-normal flex'>
										<div className='text-gray-400 font-thin line-through hidden'>Rp16.000</div>
										<div className='text-rose-violet pl-2'>{formatRupiah(paymentSummary?.deliveryFee || 0)}</div>
									</div>
								</div>
								<div className={`${!paymentSummary?.otherFee ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
									<div className='text-gray-900 text-xs font-normal flex items-center'>
										Other Fee(s)
										<TooltipInfo title="What's Included?" items={tooltipOtherFee} />
									</div>
									<div className='text-xs font-normal flex'>
										<div className='text-gray-700 pl-2'>{formatRupiah(paymentSummary?.otherFee || 0)}</div>
									</div>
								</div>
								<div className={`${!applyB2BVoucher || !b2bVoucherNominal ? 'hidden' : 'flex'} justify-between items-center mt-1`}>
									<div className='text-gray-900 text-xs font-normal'>Buy Voucher</div>
									<div className='text-xs font-normal flex'>
										<div className='text-gray-400 font-thin line-through'>{formatRupiah(b2bVoucherNominal)}</div>
										<div className='text-gray-700 pl-2'>{formatRupiah(b2BVoucherPrice)}</div>
									</div>
								</div>
								<div className={`${!paymentSummary?.myVoucherApplied ? 'hidden' : 'flex'} justify-between items-center mt-2`}>
									<div className='text-gray-900 text-xs font-normal flex items-center'>
										<TicketDiscountIcon className='h-6 w-6 mr-1' />
										Voucher Applied
										<TooltipInfo title='Voucher Applied' items={tooltipVoucherList} />
									</div>
									<div className='text-xs font-normal flex'>
										<div className='text-rose-violet pl-2'>-{formatRupiah(paymentSummary?.myVoucherApplied || 0)}</div>
									</div>
								</div>
								<div className='border-b-1 border-solid pb-2 border-gray-300' />
								<div className='flex justify-between items-center mt-2'>
									<div className='text-gray-900 text-sm font-extrabold items-center'>Total Payment</div>
									<div className='text-sm font-extrabold flex'>
										<div className='text-gray-900'>{formatRupiah(paymentSummary?.totalPayment || 0)}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{isShowBottomSheet && (
						<BottomSheet
							open={isShowBottomSheet}
							onDismiss={handleCloseBottomSheet}
							snapPoints={({ minHeight, maxHeight }) => {
								// 544 932
								if (isShowDeliveryLocation) {
									return [838];
								}
								return [minHeight];
							}}>
							{isShowSimpleProduct && <ProductSimple product={cartItem} cart={cart} handleCart={handleCart} setCartItem={setCartItem} />}
							{isShowComboProduct && <ComboDetail product={product} item={item} handleSubmitItem={handleCart} isEdit={true} />}
							{isShowDelivery && (
								<div
									className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${
										isShowDelivery ? 'translate-y-0 relative' : 'translate-y-full hidden'
									}`}>
									<div className='flex justify-center py-4 text-base text-gray-900 font-semibold'>Delivery Service</div>
									{pathnersList?.map((pathner, index) => {
										return (
											<div
												key={`pathner_${index}`}
												className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center`}
												onClick={() => {
													// setDelivery(pathner);
													dispatch(
														setCart({
															delivery: pathner,
														}),
													);
												}}>
												<div className=''>
													<div className={`text-sm capitalize text-gray-900 font-semibold`}>{`${pathner?.name} ${pathner?.serviceType}`}</div>
													<div className='text-xs text-gray-400 pt-1 font-thin'>Estimated arrival 30-40 min direct to you</div>
												</div>
												<div className='flex'>
													<div className='pl-2'>
														<input
															id='pathner'
															type='radio'
															name='pathner'
															value={pathner?.name}
															checked={delivery?.name === pathner?.name}
															onChange={() => null}
															className={`${
																delivery?.name === pathner?.name ? '!border-rose-violet' : '!border-gray-400'
															} after:!bg-rose-violet active:bg-white focus:ring-0 focus:bg-transparent`}
														/>
													</div>
												</div>
											</div>
										);
									})}
									<button
										className='mt-10 w-full bg-byzantium text-white flex justify-center font-normal text-sm p-3 border-0 items-center'
										onClick={() => {
											setIsShowDelivery(false);
											setIsShowBottomSheet(false);
										}}>
										Got It
									</button>
								</div>
							)}
							{isShowPaymentMethod && (
								<div
									className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out rounded-t-xl ${
										isShowPaymentMethod ? 'translate-y-0 relative' : 'translate-y-full hidden'
									}`}>
									<div className='flex justify-center py-4 text-base text-gray-900 font-semibold'>Select Payment Method</div>
									{paymentMethodList?.map((method, index) => {
										return (
											<div className='w-full'>
												<div className='flex justify-start py-4 text-sm text-gray-800 font-semibold capitalize'>{method?.category ?? ''}</div>
												{method?.payment_method?.map((payment, index) => {
													const isDisabled = payment?.code === 'ULTRA-VOUCHER';
													return (
														<div
															key={`payment_${index}`}
															className={`border-1 border-gray-300 border-solid rounded-md flex justify-between px-2 py-2.5 mt-2 items-center h-14 ${
																isDisabled && 'opacity-50'
															}`}
															onClick={() => {
																if (isDisabled) return;
																console.log('oncliek payment type', payment);
																setValues((prev) => {
																	return {
																		...prev,
																		paymentType: payment,
																	};
																});
															}}>
															<div className='flex items-center'>
																{payment.code === 'ULTRA-VOUCHER' ? (
																	<UltraVoucherIcon />
																) : (
																	<img alt={payment?.alt ?? ''} src={payment?.image_url} className='w-9 h-auto' />
																)}
																<div className='pl-2'>
																	<div className={`text-xs capitalize font-semibold text-gray-900`}>{`${payment?.name}`}</div>
																	{payment.code === UVBalance?.code && (
																		<div className={`text-xs pt-1 font-thin ${isDisabled ? 'text-red-700' : 'text-gray-700'}`}>
																			{isDisabled ? `Ultra Value is currently unavailable.` : `Balance ${formatRupiah(UVBalance?.value || 0)}`}
																		</div>
																	)}
																</div>
															</div>
															<div className='flex'>
																<div className='pl-2'>
																	<input
																		id='payment'
																		type='radio'
																		name='payment'
																		value={payment?.code}
																		checked={values?.paymentType?.code === payment?.code}
																		onChange={() => null}
																		disabled={isDisabled}
																		className={`${
																			values?.paymentType?.code === payment?.code ? '!border-rose-violet' : '!border-gray-400'
																		} after:!bg-rose-violet active:bg-white focus:bg-transparent`}
																	/>
																</div>
															</div>
														</div>
													);
												})}
											</div>
										);
									})}
									<button
										className='mt-10 w-full bg-byzantium text-white flex justify-center font-normal text-sm p-3 border-0 items-center'
										onClick={() => {
											setIsShowPaymentMethod(false);
											setIsShowBottomSheet(false);
										}}>
										Choose Payment
									</button>
								</div>
							)}
							{isShowVoucher && (
								<>
									<div
										className={`bg-white transition-transform duration-1000 ease-in-out rounded-t-xl h-full relative ${
											isShowVoucher ? 'translate-y-0 relative' : 'translate-y-full hidden'
										}`}>
										<div className='flex justify-start py-4 text-base text-gray-900 font-semibold mx-4'>Add Voucher</div>
										<div className='border-b-1 border-solid border-gray-200 mb-4' />
										<div className='h-full min-h-[44rem] max-h-[44rem] overflow-y-scroll overflow-x-hidden scrollbar-hide '>
											<div className={`mt-4 h-full`}>
												<div className='text-base font-semibold text-gray-900 mx-4'>My Voucher</div>
												{myVoucherList?.length ? (
													<>
														{!isShowMoreVoucher
															? myVoucherList?.slice(0, 3)?.map((voucher, index) => {
																	return (
																		<Voucher
																			voucherKey={`my_voucher_all_${index}`}
																			showLabelCount={false}
																			type={'my_voucher'}
																			data={voucher}
																			handleUseVoucher={(data) => handleUpdateVoucherList(data.id, 'my_voucher', data.is_use ?? false)}
																			isDisabled={paymentSummary?.isDisableVoucher}
																			onOpenDetail={() => handleVoucherDetail(true, voucher)}
																		/>
																	);
															  })
															: myVoucherList?.map((voucher, index) => {
																	return (
																		<Voucher
																			voucherKey={`my_voucher_all_${index}`}
																			showLabelCount={false}
																			type={'my_voucher'}
																			data={voucher}
																			handleUseVoucher={(data) => handleUpdateVoucherList(data.id, 'my_voucher', data.is_use ?? false)}
																			isDisabled={paymentSummary?.isDisableVoucher}
																			onOpenDetail={() => handleVoucherDetail(true, voucher)}
																		/>
																	);
															  })}
														<div className={`${myVoucherList?.length <= 3 && 'hidden'}`}>
															{!isShowMoreVoucher ? (
																<div
																	className={`flex mt-6 justify-center items-center text-green-600 text-sm font-semibold w-full`}
																	onClick={() => {
																		console.log('onClick more voucher');
																		setIsShowMoreVoucher(true);
																	}}>
																	See More
																	<ChevronDownIcon class='h-5 w-5 text-green-600' strokeWidth={3} />
																</div>
															) : (
																<div
																	className={`flex mt-6 justify-center items-center text-green-600 text-sm font-semibold w-full`}
																	onClick={() => {
																		console.log('onClick more voucher');
																		setIsShowMoreVoucher(false);
																	}}>
																	See Less
																	<ChevronUpIcon class='h-5 w-5 text-green-600' strokeWidth={3} />
																</div>
															)}
														</div>
													</>
												) : (
													<div className='my-8 flex flex-col items-center'>
														<div className='text-gray-900 font-semibold text-base'>You don't have any voucher</div>
														<div className='text-gray-900 font-thin text-xs'>Don’t miss out! Purchase vouchers for discounts</div>
													</div>
												)}
												<div className='border-b-8 border-solid pb-5 border-gray-200 w-screen' />
												<div className={`${b2bVoucherList?.length ? 'mb-5' : ''}`}>
													<div className='text-base font-semibold text-gray-900 mt-6 mx-4'>More Voucher from Ufood</div>
													{b2bVoucherList?.length ? (
														b2bVoucherList?.map((voucher, index) => {
															return (
																<Voucher
																	voucherKey={`b2b_voucher_all_${index}`}
																	showLabelCount={false}
																	type={'b2b_voucher'}
																	data={voucher}
																	handleUseVoucher={(data, type) => handleUpdateVoucherList(data.code, 'b2b_voucher', data.is_use ?? false, type)}
																	isDisabled={paymentSummary?.isDisableVoucher}
																	onOpenDetail={() => handleVoucherDetail(true, voucher)}
																/>
															);
														})
													) : (
														<div className='my-8 flex flex-col items-center'>
															<div className='text-gray-900 font-semibold text-base'>You don't have any voucher</div>
															<div className='text-gray-900 font-thin text-xs'>Don’t miss out! Purchase vouchers for discounts</div>
														</div>
													)}
												</div>
											</div>
										</div>
										<div className='mx-4'>
											<button
												className='w-full bg-rose-violet text-white flex justify-center font-normal text-sm border-0 items-center my-4 p-3 rounded-lg'
												onClick={() => {
													handleCloseBottomSheet();
												}}>
												Submit
											</button>
										</div>
									</div>
									{showVoucherDetail.isOpen && <VoucherDetail data={showVoucherDetail.data} onClose={() => handleVoucherDetail(false)} />}
								</>
							)}
							{isShowDeliveryLocation && <DeliveryLocationDetail handleCloseDelivery={handleCloseBottomSheet} back='summary' />}
							{isTooFar && (
								<TooFar
									show={isTooFar}
									handleClickButton={() => {
										setIsTooFar(false);
										setIsShowDeliveryLocation(true);
									}}
								/>
							)}
						</BottomSheet>
					)}
					{cart && !isShowBottomSheet && (
						<Footer position='fixed' id='cart'>
							<div className={`bg-white p-4 shadow-custom transition-transform duration-1000 ease-in-out ${cart ? 'translate-y-0 relative' : 'translate-y-full hidden'}`}>
								<div className='items-center hidden'>
									<DiscountIcon />
									<div className='pl-2 text-gray-900 text-xs'>Yay, you’re saving 10k. Add more and save up to 50k!</div>
								</div>
								<button
									disabled={isLoadingCart || isLoadingDeliveryList || isLoadingVoucherList}
									className={`${
										isLoadingCart || isLoadingDeliveryList || isLoadingVoucherList ? 'bg-gray-300 text-white' : 'bg-byzantium text-white'
									} mt-2 w-full flex justify-between font-normal text-sm p-3 items-center border-0`}
									onClick={() => handleSubmit()}>
									<div>Pay and Order</div>
									<div>{formatRupiah(parseInt(paymentSummary?.totalPayment || 0))}</div>
								</button>
							</div>
						</Footer>
					)}
				</div>
			</div>
		</>
	);
};

export default Summary;
