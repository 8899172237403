import React from "react";
import bca from "../../../common/images/BCA-dummy.svg";

function PaymentMethod({ orderDetails }) {
  const paymentMethod = orderDetails?.request_post_datas.paymentMethod;

  const paymentDetails = {
    VADIRECTBCA: {
      imgSrc:
        "https://uvdev.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2019-09-30T03%3A39%3A05.606Z1569814745-group.jpeg",
      altText: "BCA",
      label: "BCA Virtual Account",
    },
    VABNI: {
      imgSrc:
        "https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2022-09-02T02%3A40%3A55.520Z1662086455-BNI.png",
      altText: "BNI",
      label: "BNI Virtual Account",
    },
    VAMANDIRI: {
      imgSrc:
        "https://uvproduction.oss-ap-southeast-5.aliyuncs.com/nero/assets/images/2023-07-11T11%3A21%3A41.987Z1689074501-group.png",
      altText: "Mandiri",
      label: "Mandiri Virtual Account",
    },
  };

  const paymentInfo = paymentDetails[paymentMethod];

  return (
    <div
      className="px-4"
      style={{
        border: "1px solid #D3D3D3",
        paddingTop: "13px",
        paddingBottom: "16px",
      }}
    >
      <p className="text-xs text-gray-500 font-normal">Payment Method</p>
      <div
        className="flex mt-5 items-center"
        style={{
          border: "1px solid #D3D3D3",
          padding: "16px",
          borderRadius: "10px",
          gap: "6px",
        }}
      >
        {paymentInfo && (
          <>
            <img
              src={paymentInfo.imgSrc}
              alt={paymentInfo.altText}
              className="w-9 h-auto"
            />
            <p>{paymentInfo.label}</p>
          </>
        )}
      </div>
    </div>
  );
}

export default PaymentMethod;
