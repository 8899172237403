import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Home from "../components/Home/index";
import Allrestaurants from "../components/Restaurants/Allrestaurants";
import Restaurants from "../components/Restaurants/Index";
import Cart from "../components/Cart/Cartlist";
import Transactionorder from "../components/Checkout/Transactionorder";
import Ordercompleted from "../components/Checkout/Ordercompleted";
import Paymentdetails from "../components/Home/Paymentdetails";
import Virtualaccount from "../components/Checkout/Virtualaccount";
import Paymentcompleted from "../components/Home/Paymentcompleted";
import Cardlist from "../components/Home/Cardlist";
import Myvoucher from "../components/Discount/Myvoucher";
import Membershiplist from "../components/Home/Membershiplist";
import Membershippoints from "../components/Home/Membershippoints";
import Discountoffer from "../components/Discount/Discountoffer";
import HistoryList from "../components/Orders/OrdersHistoryList/OrderHistory";
import OrderHistoryDetails from "../components/Orders/OrderHistoryDetails/OrderHistoryDetails";
import TrackOrder from "../components/Orders/TrackOrder";
import FavoriteList from "../components/Restaurants/FavoriteList/FavoriteList";
import Addaddressform from "../components/Home/Addaddressform";
import Addressyet from "../components/Home/Addressyet";
import AddressLists from "../components/Home/AddressLists";
import Ongoing from "../components/Home/Ongoing";
import CustomGoogleMap from "../components/GoogleMaps";
import Outlet from "../pages/outlet/index";

import Refpage from "../components/Layout/Refpage";
import NewHome from "../components/Home";
import Search from "../components/Search";
import Summary from "../pages/summary";
import Payment from "../pages/payment";
import Tracking from "../pages/tracking";

const useRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const routes = [
    {
      exact: true,
      path: "/",
      component: Home,
    },
    {
      exact: true,
      path: "/revamp",
      component: NewHome,
    },
    {
      exact: true,
      path: "/search",
      component: Search,
    },
    {
      exact: true,
      path: "/google-map",
      component: CustomGoogleMap,
    },
    {
      exact: true,
      path: "/summary",
      component: Summary,
    },
    {
      exact: true,
      path: "/address",
      component: AddressLists,
    },
    {
      exact: true,
      path: "/add-address",
      component: Addaddressform,
    },
    {
      exact: true,
      path: "/restaurants",
      component: Allrestaurants,
    },
    {
      exact: true,
      path: "/restaurant/:storeSlug/:categorySlug/:productSlug",
      component: Restaurants,
    },
    {
      exact: true,
      path: "/restaurant/:storeSlug/:categorySlug",
      component: Restaurants,
    },
    {
      exact: true,
      path: "/restaurant/:storeSlug",
      component: Restaurants,
    },
    {
      exact: true,
      path: "/cart",
      component: Cart,
    },
    {
      exact: true,
      path: "/favourites",
      component: FavoriteList,
    },
    {
      exact: true,
      path: "/transaction-order",
      component: Transactionorder,
    },
    {
      exact: true,
      path: "/process-order",
      component: Virtualaccount,
    },
    {
      exact: true,
      path: "/order-completed/:orderID",
      component: Ordercompleted,
    },
    {
      exact: true,
      path: "/track-order/:orderType/:orderNumber",
      component: TrackOrder,
    },
    {
      exact: true,
      path: "/history/:orderType/:orderNumber",
      component: OrderHistoryDetails,
    },
    {
      exact: true,
      path: "/history/:orderType",
      component: HistoryList,
    },
    {
      exact: true,
      path: "/history",
      component: HistoryList,
    },
    {
      exact: true,
      path: "/discount",
      component: Discountoffer,
    },
    {
      exact: true,
      path: "/my-voucher",
      component: Myvoucher,
    },
    {
      exact: true,
      path: "/payment",
      component: Payment,
    },
    {
      exact: true,
      path: "/payment-details",
      component: Paymentdetails,
    },
    {
      exact: true,
      path: "/payment-success",
      component: Paymentcompleted,
    },
    {
      exact: true,
      path: "/card-list",
      component: Cardlist,
    },
    {
      exact: true,
      path: "/membership-list",
      component: Membershiplist,
    },
    {
      exact: true,
      path: "/membership-points",
      component: Membershippoints,
    },
    {
      exact: true,
      path: "/add-address-yet",
      component: Addressyet,
    },
    {
      exact: true,
      path: "/on-going",
      component: Ongoing,
    },
    {
      exact: true,
      path: "/outlet/:slug",
      component: Outlet,
    },
    {
      path: "/refpage/:slugtext",
      component: Refpage,
    },
    {
      exact: true,
      path: "/tracking",
      component: Tracking,
    },
  ];

  return {
    routes,
  };
};

export default useRouter;
