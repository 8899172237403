import { useState } from "react";

const useSummaryTooltipInfo = () => {
  /*======================== UseState ======================== */

  const [isShow, setIsShow] = useState(false);

  /*======================== Handler ======================== */

  const handleShow = () => {
    setIsShow(!isShow);
  };

  /*======================== Return ======================== */

  return {
    isShow,
    handleShow,
  };
};

export default useSummaryTooltipInfo;
