import { Route, Switch } from "react-router-dom";
import useRouter from "./useRouter";
import Layout from "../components/Layout";
import Page404 from "../Page404";
import ConnectionChecker from '../components/Errors/ConnectionChecker';

const RouteConfig = () => {
  const { routes } = useRouter();

  return (
    <ConnectionChecker>
      <Switch>
        <Layout>
          {routes.map(({ exact, index, path, component }) => (
            <Route key={path} {...{ index, exact, path, component }} />
          ))}
        </Layout>
        <Route component={Page404} />
      </Switch>
    </ConnectionChecker>
  );
};

export default RouteConfig;
