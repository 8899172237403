import { useState, useEffect, useCallback } from "react";
import homeGrayIcon from "../../../../../../common/images/revamp/icon/home-gray.svg";
import homePurpleIcon from "../../../../../../common/images/revamp/icon/home-purple.svg";
import officeGrayIcon from "../../../../../../common/images/revamp/icon/office-gray.svg";
import officePurpleIcon from "../../../../../../common/images/revamp/icon/office-purple.svg";
import otherGrayIcon from "../../../../../../common/images/revamp/icon/tag-gray.svg";
import otherPurpleIcon from "../../../../../../common/images/revamp/icon/tag-purple.svg";
import { getGeocode } from "use-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import {
  getSavedList,
  setLocation as setLocationRedux,
} from "../../../../../../store/reducers/location";
import { axiosInstance } from "../../../../../../utils/axiosInstance";

const useFormAddress = (props) => {
  /*======================== Props ======================== */

  const { data, type, onClose } = props;

  /*======================== UseState ======================== */

  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    addressName: "",
    addressDetail: "",
    type: "home",
    contactName: "",
    contactNumber: "",
    id_customer_address: "",
  });
  const [location, setLocation] = useState({
    address: null,
    name: null,
    lat: data.lat,
    lng: data.lng,
  });

  const [errors, setErrors] = useState('');

  /*======================== Store ======================== */

  const dispatch = useDispatch();
  const user_id = useSelector((state) => state.user?.customerID || "");

  /*======================== Handler ======================== */

  const onLoadMap = useCallback(() => {
    setTimeout(() => {
      const elementsToHide = document.querySelectorAll(".gmnoprint");
      elementsToHide.forEach((el) => (el.style.display = "none"));
    }, 1000);
  }, []);

  const handleDragMap = async (data) => {
    const lat = data?.latLng?.lat();
    const lng = data?.latLng?.lng();
    getGeocode({ location: { lat, lng } }).then((results) => {
      const result = results[0];
      handleFormatingAddress({
        lat,
        lng,
        formatted_address: result?.formatted_address || "",
        address_components: result?.address_components || {},
      });
    });
  };

  const handleFormatingAddress = ({
    lat,
    lng,
    formatted_address,
    address_components,
  }) => {
    let country = "";
    let state = "";
    let city = "";
    let village = "";
    let district = "";
    let postalCode = "";

    address_components?.forEach((item) => {
      if (item.types.includes("country")) {
        country = item.long_name;
      }
      if (item.types.includes("administrative_area_level_1")) {
        state = item.long_name;
      }
      if (item.types.includes("administrative_area_level_2")) {
        city = item.long_name;
      }
      if (item.types.includes("administrative_area_level_3")) {
        village = item.long_name;
      }
      if (item.types.includes("administrative_area_level_4")) {
        district = item.long_name;
      }
      if (item.types.includes("postal_code")) {
        postalCode = item.long_name;
      }
    });
    const dataAddress = {
      address: formatted_address,
      name: district || village,
      lat,
      lng,
      country,
      state,
      city,
      village,
      district,
      postalCode,
    };
    setLocation(dataAddress);
  };
  const handleOnClickMap = async (data) => {
    const lat = data?.latLng?.lat();
    const lng = data?.latLng?.lng();
    const place_id = data?.placeId;
    if (lat && lng && place_id) {
      getGeocode({ placeId: place_id }).then((results) => {
        const result = results[0];
        handleFormatingAddress({
          lat,
          lng,
          formatted_address: result?.formatted_address || "",
          address_components: result?.address_components || {},
        });
      });
    }
  };
  const handleFormValue = (type, value) => {
    switch (type) {
      case "contactNumber":
        // const sanitizedValue = value.replace(/\D|^0+/g, "");
        const sanitizedValue = value.replace(/\D+/g, "");
        setFormValue({
          ...formValue,
          contactNumber: sanitizedValue,
        });
        break;
      default:
        setFormValue({
          ...formValue,
          [type]: value,
        });
        break;
    }
  };
  const handleValidate = (type) => {
    if (errors && type === "addressName") {
      return errors
    }

    switch (true) {
      case type === "contactNumber" &&
        formValue.contactNumber.length > 0 &&
        formValue.contactNumber.length < 9:
        return "Your phone number should contain at least 9 digits";

      default:
        return "";
    }
  };
  const handleDisableSubmit = () => {
    if (loading) return true;
    if (
      formValue.addressName.length > 0 &&
      formValue.contactName.length > 0 &&
      formValue.contactNumber.length > 0 &&
      formValue.contactNumber.length <= 15
    ) {
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    setErrors('');
    setLoading(true);
    const payload = {
      user_id,
      ...location,
      ...formValue,
      latitude: location.lat,
      longitude: location.lng,
    };
    if (type === "add") {
      delete payload.id_customer_address;
    }

    const formData = new FormData();

    // Dynamically append each key-value pair from the object to formData
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await axiosInstance.post(`address/${type}`, formData);
      if (response.status === 200) {
        onClose();
        dispatch(getSavedList(user_id));
      }
    } catch (error) {
      console.log("error", error.response.data.message);
    
      if (error?.response && error?.response?.data?.message) {
        setErrors(error.response.data.message || {});
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    dispatch(
      setLocationRedux({
        savedIsOnSearch: true,
        savedInitialForm: { ...formValue, formType: type },
      })
    );
    onClose();
  };

  /*======================== UseEffect ======================== */

  useEffect(() => {
    if (data) {
      setFormValue({
        addressName: data.addressName || "",
        addressDetail: data.addressDetail || "",
        type: data.type || "home",
        contactName: data.contactName || "",
        contactNumber: data.contactNumber || "",
        id_customer_address: data.id || data.id_customer_address || "",
      });
      getGeocode({ location: { lat: data.lat, lng: data.lng } }).then(
        (results) => {
          const result = results[0];
          handleFormatingAddress({
            lat: data.lat,
            lng: data.lng,
            formatted_address: result?.formatted_address || "",
            address_components: result?.address_components || {},
          });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  /*======================== Others ======================== */

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    streetViewControl: false,
    rotateControl: false,
  };
  const typeOptions = [
    {
      label: "Home",
      value: "home",
      iconInactive: homeGrayIcon,
      iconActive: homePurpleIcon,
    },
    {
      label: "Office",
      value: "office",
      iconInactive: officeGrayIcon,
      iconActive: officePurpleIcon,
    },
    {
      label: "Other",
      value: "other",
      iconInactive: otherGrayIcon,
      iconActive: otherPurpleIcon,
    },
  ];

  /*======================== Return ======================== */

  return {
    typeOptions,
    mapOptions,
    formValue,
    location,
    onLoadMap,
    handleDragMap,
    handleOnClickMap,
    handleFormValue,
    handleValidate,
    handleDisableSubmit,
    handleSubmit,
    handleSearch,
  };
};

export default useFormAddress;
