import { twMerge } from 'tailwind-merge';
import useSearchProduct from './useSearchProduct';
import { formatRupiah } from '../../../Helpers/SettingHelper';
import star from '../../../../common/images/revamp/icon/star.svg';
import React from 'react';
import ImageLoader from '../../../ImageLoader';
import { useHistory } from 'react-router';

const SearchProduct = React.memo((props) => {
	const { products, storeSlug } = props;
	const { selected, handleSelected } = useSearchProduct(props);
	const history = useHistory();

	return (
		<>
			<div className=''>
				<div className='grid grid-flow-col auto-cols-min overflow-x-auto snap-x snap-mandatory scroll-smooth px-4 scroll-px-4 gap-2 hide-scroll mb-2'>
					{products?.map((product, index) => (
						<div
							key={index}
							className='snap-start w-[90px] cursor-pointer'
							onClick={() => {
								console.log('storeSlug', storeSlug);
								history.push(`/outlet/${storeSlug}`);
							}}>
							<div className={twMerge('mb-1.5 relative transition-all')}>
								<div
									className={twMerge(
										'absolute bottom-1.5 right-1.5 rounded hidden justify-around items-center bg-white border border-solid border-ufdPurple text-ufdPurple shadow-[0px_9px_28px_8px_#0000000D] text-xl transition-all text-center',
										selected[product.id] && 'w-auto',
									)}>
									<div onClick={() => handleSelected('remove', product)} className={twMerge('w-6 h-6 grid place-content-center text-2xl', selected[product.id] ? '' : 'hidden')}>
										-
									</div>
									<span className={twMerge('text-black text-sm w-6 font-semibold', selected[product.id] ? '' : 'hidden')}>{selected[product.id]?.qty}</span>
									<div onClick={() => handleSelected('add', product)} className='w-6 h-6 grid place-content-center text-2xl'>
										+
									</div>
								</div>
								<div className='w-[5.6rem] h-[5.6rem]'>
									<ImageLoader src={product?.productImage} altImage={product?.productName} />
								</div>
							</div>

							<div className='space-y-0.5'>
								{product?.rating && (
									<div className='flex items-center gap-1'>
										<img src={star} alt='star' className='!w-auto' />
										<span className='text-xxs font-medium text-black37'>
											{product?.rating || 0} ({product?.totalRating || 0})
										</span>
									</div>
								)}
								<p className='line-clamp-2 text-xxs font-medium text-black37'>{product?.productName}</p>
								<div className='line-clamp-1 text-xxs text-black37 font-semibold'>
									{formatRupiah(product?.productPrice)}
									<span className={`line-through text-[7px] text-xxxs text-[#909090] ml-1 ${!Number(product?.productCost) && 'hidden'}`}>{formatRupiah(product?.productCost)}</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
});

export default SearchProduct;
