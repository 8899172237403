import React from 'react';
import { format } from 'date-fns';
import copy from '../../../common/images/copy-02.svg';
import useToast from '../../Toast';

function DetailTransaction({ orderDetails }) {
	const { showToast } = useToast();

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				console.log('copy', text);
				showToast('info', 'copied');
			})
			.catch((err) => {
				console.error('Failed to copy: ', err);
			});
	};

	return (
		<div className='px-4'>
			<h2 className='text-sm font-semibold'>Detail Transaction</h2>
			<div className='flex justify-between mt-4'>
				<p className='text-xs font-normal'>Order Number</p>
				<div className='flex gap-2'>
					<p className='text-xs font-semibold text-gray-800'>{orderDetails.orderNumber}</p>
					<img src={copy} alt='Copy' className='cursor-pointer' onClick={() => copyToClipboard(orderDetails.orderNumber)} />
				</div>
			</div>
			<div className='flex justify-between mt-2'>
				<p className='text-xs font-normal'>Purchased on</p>
				<p className='text-xs font-semibold text-gray-800'>{format(new Date(orderDetails.order_date), 'dd MMM y, hh:mm aa')}</p>
			</div>
			<div className='flex justify-between mt-2'>
				<p className='text-xs font-normal'>Status</p>
				<div className='text-xs font-semibold'>
          {orderDetails.orderStatus === '1' && <div className='sr-process'>Waiting For Merchant Confirmation</div>}
          {(orderDetails.orderStatus === '2' || orderDetails.orderStatus === '3') && <div className='sr-process'>On Progress</div>}
					{orderDetails.orderStatus === '4' && <div className='sr-completed'>Completed</div>}
					{orderDetails.orderStatus === '5' && <div className='sr-cancel'>Canceled</div>}
					{orderDetails.orderStatus === '6' && <div className='sr-waiting-payment'>Waiting Payment</div>}
				</div>
			</div>
		</div>
	);
}

export default DetailTransaction;
